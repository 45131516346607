import { memo, useId } from 'react';
import styles from './preferences.module.css';
import { PreferencePresenter } from './preferences_presenter';
import { createRequirement } from './requirement/requirement';

export function Preferences({
  Requirement,
  requirements,
}) {
  const titleId = useId();

  return (
    <div className={styles.preferences} aria-labelledby={titleId}>
      <h2 id={titleId}>Requirements</h2>
      <div>
        {requirements.map((rq, index) => (
          <Requirement store={rq.store} index={index} key={rq.id}/>
        ))}
      </div>
    </div>
  );
}
