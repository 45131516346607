import { makeObservable, observable, action } from "mobx"

export class OutputStore {
  exercises = [];

  constructor() {
    makeObservable(this, {
      exercises: observable.ref,
    });
  }
}

export class OutputPresenter {
  constructor() {
    makeObservable(this, {
      setExercises: action,
    });
  }

  setExercises(store, exercises) {
    store.exercises = exercises;
  }
}
