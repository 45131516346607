export function createAudioService({
  vowels,
  context,
  consonants,
}) {
  const vowelStates = Object.keys(vowels)
    .map(id => [id, { kind: 'unloaded' }]);

  const consonantStates = Object.keys(consonants.pulmonicConsonants)
    .map(id => [id, { kind: 'unloaded' }]);

  return new AudioService(
    context,
    vowels,
    consonants,
    new Map(vowelStates),
    new Map(consonantStates),
  );
}

export class AudioService {
  #ctx = undefined;
  #vowelConfig = undefined;
  #consonantConfig = undefined;
  #activated = false;

  #vowelState = undefined;
  #consonantState = undefined;

  constructor(
    ctx,
    vowelConfig,
    consonantConfig,
    vowelState,
    consonantState,
  ) {
    this.#ctx = ctx;
    this.#vowelConfig = vowelConfig
    this.#consonantConfig = consonantConfig;
    this.#vowelState = vowelState;
    this.#consonantState = consonantState;
  }

  playVowel(id) {
    return this.#playCachableAudioBuffer(
      id,
      `/audio/vowel/${id}.mp3`,
      this.#vowelState,
      this.#vowelConfig,
    );
  }

  playConsonant(id) {
    return this.#playCachableAudioBuffer(
      id,
      `/audio/consonant/${id}.mp3`,
      this.#consonantState,
      this.#consonantConfig.pulmonicConsonants,
    );
  }

  async #playCachableAudioBuffer(id, url, cache, config) {
    await this.#activate();

    try {
      if (!config[id].audio) {
        cache.set(id, { kind: 'unavailable', reason: 'no audio' });
        return;
      }

      const state = cache.get(id);

      if (state.kind === 'unavailable') {
        return;
      }

      cache.set(id, { kind: 'unavailable', reason: 'running' });

      let audioBuffer;
      if (state.kind === 'idle') {
        audioBuffer = state.audioBuffer;
      } else if (state.kind === 'unloaded') {
        const result = await this.#getAudioBuffer(url);

        if (!result.ok) {
          cache.set(id, { kind: 'unavailable', reason: 'failed' });
          return;
        }

        audioBuffer = result.value;
      }

      const source = this.#ctx.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(this.#ctx.destination);
      source.start();

      cache.set(id, { kind: 'idle', audioBuffer });
    } catch (error) {
      console.error(id, url, error);
    }
  }

  #playAudio(audioBuffer) {
  }

  async #getAudioBuffer(url) {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      return {
        ok: true,
        value: await this.#ctx.decodeAudioData(arrayBuffer),
      };
    } catch (error) {
      console.error('Failed to download', url, error);
      return { ok: false };
    }
  }

  async #activate() {
    if (this.#activated) return;
    console.log(this.#ctx);
    await this.#ctx.resume();
    this.#activated = true;
  }
}
