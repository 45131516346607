import { makeObservable, observable } from "mobx"

class ConsonantRequirementStore {
  constraint = undefined;

  constructor(constraint) {
    makeObservable(this, {
      constraint: observable.ref,
    });

    this.constraint = constraint;
  }
}

export class ConsonantRequirementPresenter {
  #consonantConfig = undefined;
  #languageConfig = undefined;
  #metaConfig = undefined;

  constructor(
    consonantConfig,
    metaConfig,
    languageConfig,
  ) {
    this.#consonantConfig = consonantConfig;
    this.#languageConfig = languageConfig;
    this.#metaConfig = metaConfig;
  }

  createStore(constraint = { kind: 'any' }) {
    return new ConsonantRequirementStore(constraint);
  }

  createConsonant(store) {
    try {
      const consonant = this.#resolveConsonant(
        store,
        store.constraint,
        Object.values(this.#consonantConfig.pulmonicConsonants)
      );

      return {
        glyph: consonant.glyph,
        value: { kind: 'consonant', consonant }
      };
    } catch (e) {
      console.error(store);
      throw new Error(e);
    }
  }

  #resolveConsonant(store, constraint, consonants) {
    switch (constraint.kind) {
      case 'exact':
        return consonants.find(it => it.id === constraint.value);

      case 'match':
        return this.#matchConsonants(
          store,
          constraint,
          consonants,
        );

      case 'localised':
        return this.#resolvedLocalisedConsonant(
          store,
          constraint.lang,
          constraint.where,
          consonants,
        );

      case 'one-of':
        return this.#consonantConfig.pulmonicConsonants[
          pickRandom(Object.values(constraint.options))
        ];

      case 'any':
        return pickRandom(consonants);

      default:
        throw new Error(constraint);
    }
  }

  #resolvedLocalisedConsonant(store, lang, constraint, consonants) {
    const whitelist = new Set(
      this.#languageConfig[lang].consonants.whitelist,
    );

    return this.#resolveConsonant(
      store,
      constraint,
      consonants.filter(c => whitelist.has(c.id)),
    );
  }

  #matchConsonants(store, constraint, consonants) {
    const {
      manner = { kind: 'any' },
      place = { kind: 'any' },
      voiced = { kind: 'any' },
    } = constraint;

    const lsa = consonants.filter(c => {
      switch (manner.kind) {
        case 'any':
          return true;

        case 'exact':
          return c.manner === manner.value;

        default:
          throw new Error(manner.kind);
      }
    });

    const lsb = lsa.filter(c => {
      switch (place.kind) {
        case 'any':
          return true;

        case 'exact':
          return c.place === place.value;

        default:
          throw new Error(place.kind);
      }
    });

    const lsc = lsb.filter(c => {
      switch (voiced.kind) {
        case 'any':
          return true;

        case 'exact':
          return c.voiced === voiced.value;

        default:
          throw new Error(voiced.kind);
      }
    });

    return pickRandom(lsc);
  }
}

function pickRandom(list) {
  return list[Math.floor(Math.random() * list.length)]
}
