import {
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { ResponsiveNotation } from './notation';
import { SineSynth } from './sine_synth';

export function createNotation({
  context,
}) {
  return memo(props => {
    const synth = useMemo(() => (
      SineSynth.create({ ctx: context })
    ), []);

    const playSteps = useCallback(async steps => {
      await context.resume();

      for (const stepGroup of steps) {
        for (const step of stepGroup) {
          await synth.playQuarterNote({ note: step });
        }
      }
    }, [synth]);

    // install & uninstall
    useEffect(() => {
      try {
        synth.connect(context.destination);
        return () => synth.disconnect();
      } catch (error) {
        console.error(error);
      }
    }, [synth]);

    return (
      <ResponsiveNotation playSteps={playSteps} {...props}/>
    )
  });
}

