import {
  useId,
  useCallback,
  useMemo,
  useState,
} from 'react';
import useMeasure from 'react-use-measure';
import styles from './notation.module.css';

export function ResponsiveNotation({
  steps,
  playSteps,
}) {
  const [ref, bounds] = useMeasure();
  const [playing, setPlaying] = useState(false);
  const icon = playing ? "⏸" : "▶️";

  const onClick = useCallback(async () => {
    setPlaying(true);
    await playSteps(steps);
    setPlaying(false);
  }, [playSteps, steps]);

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.notationContainer}>
        <Notation
          steps={steps}
          width={bounds.width}
        />
      </div>
      <button
        disabled={playing}
        onClick={onClick}
        className={styles.button}
      >{icon}</button>
    </div>
  );
}

const STAFF_CHAR = '𝄞';
const SHARP_CHAR = '♯';

export function Notation({
  steps,
  width,
}) {
  const height = 64;
  const heightOfStaff = 32;
  const staffLineDist = 8;
  const staffDy = (height - heightOfStaff) / 2;
  const staffYs = Array.from({ length: 5 }, (_, i) => staffDy + (i * staffLineDist));

  const {
    extraStaffLines,
    notes,
    sharps,
  } = useMemo(() => {
    const MIDDLE_C = staffDy + staffLineDist * 5;
    const NOTE_SEPERATOR = 8 * 3.25;

    const FADE_AREA = width*.3;
    const notes = [];
    const sharps = [];
    const extraStaffLines = [];

    let dx = 8 * 7;
    for (const bar of steps) {
      for (const step of bar) {
        const { diff, sharp } = noteOffset(step);
        const cy = MIDDLE_C - (diff * 4);
        let opacity = 1;
        if (dx > (width - FADE_AREA)) {
          opacity -= (dx-(width-FADE_AREA))/FADE_AREA;
        }

        const fill = `rgba(0, 0, 0, ${opacity})`;

        notes.push({
          ellipse: {
            key: `e-${dx}-${step}`,
            cx: dx,
            cy,
            rx: 5,
            ry: 4,
            fill,
          },
          staff: {
            key: `s-${dx}-${step}`,
            d: `M${dx + 4} ${cy} V${cy - (8 * 3.5)}`,
            stroke: fill,
          },
        });

        if (sharp) {
          const isOdd = diff % 2 === 1;
          sharps.push({
            key: `sharp-${dx}-${step}`,
            x: dx - (8 * 2),
            y: cy - (8 * (isOdd ? -0.9 : -0.5)),
            fill,
          })
        }

        if (step <= 0) {
          extraStaffLines.push({
            key: `ex-${dx}-${step}`,
            stroke: fill,
            d: `M${dx - 8} ${cy} H${dx + 8}`,
          });
        }

        dx += NOTE_SEPERATOR;
      }
    }

    return { notes, sharps, extraStaffLines };
  }, [steps, width]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      width="100%"
      height={height}
    >
      <path d={`M0 ${staffYs[0]} H4 V${staffYs[4]} H0`} fill="black"/>
      <path d={`
        M${width - 2} ${staffYs[0]}
        H${width}
        V${staffYs[4]}
        H${width - 2}
      `} fill="black"/>

      <path d={`M0 ${staffYs[0]} H${width}`} className={styles.staffLine}/>
      <path d={`M0 ${staffYs[1]} H${width}`} className={styles.staffLine}/>
      <path d={`M0 ${staffYs[2]} H${width}`} className={styles.staffLine}/>
      <path d={`M0 ${staffYs[3]} H${width}`} className={styles.staffLine}/>
      <path d={`M0 ${staffYs[4]} H${width}`} className={styles.staffLine}/>

      {notes.map(note => <ellipse className={styles.noteEllipse} {...note.ellipse}/>)}
      {notes.map(note => <path strokeWidth={2} {...note.staff}/>)}
      {extraStaffLines.map(e => <path stroke="black" {...e}/>)}
      {sharps.map(t => <text className={styles.noteSharp} {...t}>{SHARP_CHAR}</text>)}

      <text className={styles.clef} x={12} y={staffYs[4]}>{STAFF_CHAR}</text>
    </svg>
  );
}

function noteOffset(note) {
  // ---------- C --- D --- E  F --- G --- A --- B
  const diff = [0, 0, 1, 1, 2, 3, 3, 4, 4, 5, 5, 6]
  const sharp = [0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0]
  const names = 'CCDDEFFGGAAB';

  return {
    note,
    diff: diff[note % 12] + (Math.floor(note / 12) * 7),
    name: names[note % 12],
    sharp: sharp[note % 12] === 1,
  };
}
