import { memo, useMemo, useCallback, useId } from 'react';
import styles from './ipa-legend.module.css';

export function createIpaLegend({ audio }) {
  const playVowel = id => {
    audio.playVowel(id);
  };

  const playConsonant = id => {
    audio.playConsonant(id);
  };

  const Vowel = memo(props => (
    <VowelLegendItem playVowel={playVowel} {...props}/>
  ));

  const Consonant = memo(props => (
    <ConsonantLegendItem playConsonant={playConsonant} {...props}/>
  ));

  return memo((props) => (
    <IpaLegend
      Consonant={Consonant}
      Vowel={Vowel}
      {...props}
    />
  ))
}

export function IpaLegend({
  legend,
  sound,
  Consonant,
  Vowel,
}) {
  const items = useMemo(() => legend.map((item, index) => {
    switch (item.kind) {
      case 'consonant':
        return <Consonant consonant={item.consonant}/>;

      case 'vowel':
        return <Vowel vowel={item.vowel}/>;

      default:
        throw new Error();
    }
  }), [
    legend,
    Vowel,
    Consonant,
  ]);

  const titleId = useId();

  return (
    <div aria-labelledby={titleId}>
      <p><strong id={titleId}>/{sound}/, IPA Legend</strong></p>
      <ul key={sound} className={styles.list}>
        {items.map((it, idx) => <li key={`${sound}-${idx}`}>{it}</li>)}
      </ul>
    </div>
  );
}

function ConsonantLegendItem({
  consonant,
  playConsonant,
}) {
  const labelId = useId();
  const { id, manner, place, url } = consonant;
  const onClick = useCallback(() => playConsonant(id), [id]);
  const voiceDescriptor = consonant.voiced ? 'Voiced' : 'Unvoiced';
  return (
    <p aria-labelledby={labelId}>
      <button onClick={onClick}>hear</button>{" "}
      <a id={labelId} href={url} target="_blank">{consonant.glyph}</a>:
      {" "}{voiceDescriptor} {manner} {place} Consonant.
    </p>
  );
}

function VowelLegendItem({
  vowel,
  playVowel,
}) {
  const labelId = useId();
  const { backness, height, roundness, url } = vowel;
  const onClick = useCallback(() => playVowel(vowel.id), [vowel.id]);

  return (
    <p aria-labelledby={labelId}>
      <button onClick={onClick}>hear</button>{" "}
      <a id={labelId} href={url} target="_blank">{vowel.glyph}</a>:
      {" "}{height} {backness} {roundness} Vowel.
    </p>
  );
}
