import { makeObservable, observable } from "mobx"

class PreferenceStore {
  nextRequirementId = 0;
  requirements = undefined;

  constructor(
    requirements,
    requirementIdStart = 0,
  ) {
    makeObservable(this, {
      requirements: observable.ref,
    });

    this.nextRequirementId = requirementIdStart
    this.requirements = requirements;
  }
}

export class PreferencePresenter {
  #requirement = undefined;

  constructor(
    requirementPresenter,
  ) {
    this.#requirement = requirementPresenter;
  }

  createStore(rs) {
    let requiementId = 1;

    const requirements = rs.map(r => (
      this.#requirement.createStore(r, requiementId++)
    ));

    return new PreferenceStore(requirements, requiementId);
  }

  createExercises(store) {
    // create groups to simplify the loop
    const groupIds = new Set(store.requirements.map(r => r.group));
    const groups = new Map(Array.from(groupIds, id => [id, []]));

    // create exercises & assign them to their groups
    for (const requirement of store.requirements) {
      if (!requirement.enable) continue;
      const exercise = this.#requirement.createExercise(requirement);
      groups.get(exercise.group).push(exercise);
    }

    // ensure their is only one exercise per group
    return Array.from(groups.values(), pickRandom).filter(it => !!it)
  }
}

function pickRandom(list) {
  return list[Math.floor(Math.random() * list.length)]
}
