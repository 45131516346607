
const MIDDLE_C_MIDI = 60 - 69;

export class SineSynth {
  #ctx;
  #osc;
  #amp;
  #dyn;
  #quarter;

  constructor(ctx, osc, amp, dyn, quarter = 300) {
    this.#ctx = ctx;
    this.#amp = amp;
    this.#osc = osc;
    this.#dyn = dyn;
    this.#quarter = quarter;
  }

  connect(dest) {
    this.#osc.connect(this.#amp);
    this.#amp.connect(this.#dyn);
    this.#dyn.connect(dest);
  }

  disconnect() {
    this.#dyn.disconnect();
    this.#amp.disconnect();
    this.#osc.disconnect();
  }

  async playQuarterNote({ note, sustain = 0.75, volume = 0.25 }) {
    this.#osc.frequency.value = this.#freq(note);
    this.#amp.gain.value = volume;
    await wait(this.#quarter * sustain);

    this.#amp.gain.value = 0;
    await wait(this.#quarter * (1-sustain));
  }

  static create({ ctx, quarter }) {
    const osc = ctx.createOscillator();
    const amp = ctx.createGain();
    const dyn = ctx.createDynamicsCompressor();

    osc.start()
    amp.gain.value = 0;
    dyn.threshold.value = -80;
    dyn.knee.value = 40;
    dyn.ratio.value = 20;
    dyn.attack.value = 0;
    dyn.release.value = 0.25;

    return new SineSynth(ctx, osc, amp, dyn, quarter);
  }

  #freq(note) {
    // when note = 0, note = middle c, therefore
    const midi = note + MIDDLE_C_MIDI;
    return 440 * Math.pow(2, midi / 12);
  }
}

function wait(ms) {
  return new Promise(r => setTimeout(() => r(), ms));
}
