import { configure } from "mobx"
import React from 'react';
import ReactDOM from 'react-dom/client';
import ipa from './data/ipa.json';
import meta from './data/meta.json';
import notation from './data/notation.json';
import language from './data/language.json';
import './index.css';
import { createApplication } from './ui/application';

configure({})

const root = ReactDOM.createRoot(document.getElementById('root'));
const Application = createApplication({
  data: {
    consonants: ipa.consonants,
    language,
    meta,
    notation,
    vowels: ipa.vowels,
  },
});

root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);

