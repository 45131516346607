import { memo } from 'react';
import { NotationRequirementPresenter } from './notation_requirement/notation_requirement_presenter';
import { PhoneticRequirementPresenter } from './phonetic_requirement/phonetic_requirement_presenter';
import { ConsonantRequirementPresenter } from './phonetic_requirement/consonant_requirement/consonant_requirement_presenter';
import { VowelRequirementPresenter } from './phonetic_requirement/vowel_requirement/vowel_requirement_presenter';
import { Requirement } from './requirement';
import { RequirementPresenter } from './requirement_presenter';

export function createRequirement({
  data: {
    consonants: c,
    language: l,
    meta,
    notation,
    vowels,
  },
}) {
  const requirementPresenter = new RequirementPresenter(
    new NotationRequirementPresenter(notation, meta),
    new PhoneticRequirementPresenter(
      new ConsonantRequirementPresenter(c, meta, l),
      new VowelRequirementPresenter(vowels, meta),
    ),
  );

  return {
    requirementPresenter,
    Requirement: memo(({ store }) => (
      <Requirement label={undefined}/>
    )),
  };
}
