import { makeObservable, observable } from "mobx"

class PhoneticRequirementStore {
  phonetics = undefined;

  constructor(phonetics) {
    makeObservable(this, {
      phonetics: observable.ref,
    });
    this.phonetics = phonetics;
  }
}

export class PhoneticRequirementPresenter {
  #consonant = undefined;
  #vowel = undefined;

  constructor(
    consonantRequirementPresenter,
    vowelRequirementPresenter,
  ) {
    this.#consonant = consonantRequirementPresenter;
    this.#vowel = vowelRequirementPresenter;
  }

  createStore(ps) {
    const create = p => {
      switch (p.kind) {
        case 'syllable':
          return {
            kind: 'syllable',
            label: p.label,
            about: p.about,
            items: p.items.map(create),
          };

        case 'options':
          return {
            kind: 'options',
            options: p.options.map(create),
          };

        case 'consonant':
          return {
            kind: 'consonant',
            value: this.#consonant.createStore(p.value),
          };

        case 'vowel':
          return {
            kind: 'vowel',
            value: this.#vowel.createStore(p.value),
          };

        default:
          throw new Error(p);
      }
    };

    const phonetics = ps.map(create);

    return new PhoneticRequirementStore(phonetics);
  }

  createPhonetics(store) {
    let label, about, sound = '';
    const legend = new Map();

    for (const it of store.phonetics) {
      const phonetics = this.#createPhoneticFromEntry(store, it);

      for (const { glyph, value } of phonetics.items) {
        sound += glyph;
        legend.set(glyph, value);
      }

      if (phonetics.label) {
        label = phonetics.label;
      }

      if (phonetics.about) {
        about = phonetics.about;
      }
    }

    return {
      sound,
      about,
      label,
      legend: Array.from(legend.values()),
    };
  }

  #createPhoneticFromEntry(store, entry) {
    switch (entry.kind) {
      case 'consonant':
        return {
          items: [this.#consonant.createConsonant(entry.value)],
        };

      case 'vowel':
        return {
          items: [this.#vowel.createVowel(entry.value)]
        };

      case 'syllable':
        return {
          label: entry.label,
          about: entry.about,
          items: entry.items.flatMap(it => (
            this.#createPhoneticFromEntry(store, it).items
          )),
        };

      case 'options':
        return this.#createPhoneticFromEntry(
          store,
          pickRandom(entry.options),
        );

      default:
        throw new Error('unknown phonetic node');
    }
  }
}

function pickRandom(list) {
  return list[Math.floor(Math.random() * list.length)]
}
