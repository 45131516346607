import { memo } from 'react';
import { Preferences } from './preferences';
import { PreferencePresenter } from './preferences_presenter';
import { createRequirement } from './requirement/create';

export function createPreferences({ data }) {
  const { requirementPresenter, Requirement } = createRequirement({ data });
  const presenter = new PreferencePresenter(requirementPresenter);
  const store = presenter.createStore(defaultRequirements);

  return {
    preferenceStore: presenter.createStore(defaultRequirements),
    preferencePresenter: presenter,
    Preferences: memo(props => (
      <Preferences
        requirements={store.requirements}
        Requirement={Requirement}
        {...props}
      />
    )),
  }
}

const defaultRequirements = [
  {
    label: "Warm up",
    group: 1,
    enable: true,
    notation: {
      tags: { kind: 'contains-one-of', options: ["step"] },
      difficulty: { kind: 'exact', value: 0 },
      scale: { kind: 'diatonic' },
    },
    phonetics: [{
      kind: 'options',
      options: [{
        kind: 'syllable',
        label: 'Bubbles',
        about: 'This is exercise helps with producing air.',
        items: [{
          kind: 'consonant',
          value: { kind: 'exact', value: 'voiced_bilabial_trill' },
        }, {
          kind: 'consonant',
          value: { kind: 'exact', value: 'voiced_bilabial_trill' },
        }],
      }, {
        kind: 'syllable',
        label: 'Hmming',
        about: 'This is a simple exercise to warm up the vocal cords.',
        items: [{
          kind: 'consonant',
          value: { kind: 'exact', value: 'voiceless_glottal_nonsibilantFricative' },
        }, {
          kind: 'consonant',
          value: { kind: 'exact', value: 'voiced_bilabial_nasal' },
        }],
      }],
    }],
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'exact', value: 1 },
    },
  },
  {
    label: "Sliding training",
    group: 2,
    enable: true,
    notation: {
      tags: { kind: 'contains-one-of', options: ["slide"] },
      difficulty: { kind: 'range', min: 0, max: 1 },
      scale: { kind: 'diatonic' },
    },
    phonetics: [{
      kind: 'consonant',
      value: {
        kind: 'localised',
        lang: 'en',
        where: { kind: 'match', voiced: { kind: 'exact', value: true } },
      },
    }, {
      kind: 'vowel',
      value: { kind: 'match', roundness: { kind: 'exact', value: false } },
    }],
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'exact', value: 1 },
    },
  },
  {
    label: "Basic Arpeggio",
    group: 2,
    enable: true,
    notation: {
      tags: { kind: 'contains-one-of', options: ["arpeggio"] },
      difficulty: { kind: 'range', min: 0, max: 2 },
      scale: { kind: 'diatonic' },
    },
    phonetics: [{
      kind: 'consonant',
      value: {
        kind: 'localised',
        lang: 'en',
        where: { kind: 'match', voiced: { kind: 'exact', value: true } },
      },
    }, {
      kind: 'vowel',
      value: { kind: 'match', roundness: { kind: 'exact', value: false } },
    }],
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'exact', value: 1 },
    },
  },
  {
    label: 'Shift Vowel',
    group: 3,
    enable: true,
    notation: {
      tags: { kind: 'contains-one-of', options: ["arpeggio", "step"] },
      difficulty: { kind: 'range', min: 1, max: 4 },
      scale: { kind: 'diatonic' },
    },
    phonetics: [{
      kind: 'options',
      options: [{
        kind: 'syllable',
        label: 'from Back to Front',
        items: [{
          kind: 'consonant',
          value: {
            kind: 'localised',
            lang: 'en',
            where: { kind: 'any' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'exact', value: 'back' },
            height: { kind: 'any' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'exact', value: 'front' },
            height: { kind: 'any' },
          },
        }],
      }, {
        kind: 'syllable',
        label: 'from Front to Back',
        items: [{
          kind: 'consonant',
          value: {
            kind: 'localised',
            lang: 'en',
            where: { kind: 'any' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'exact', value: 'front' },
            height: { kind: 'any' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'exact', value: 'back' },
            height: { kind: 'any' },
          },
        }],
      }, {
        kind: 'syllable',
        label: 'from Open to Close',
        items: [{
          kind: 'consonant',
          value: {
            kind: 'localised',
            lang: 'en',
            where: { kind: 'any' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'any' },
            height: { kind: 'exact', value: 'open' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'any' },
            height: { kind: 'exact', value: 'close' },
          },
        }],
      }, {
        kind: 'syllable',
        label: 'from Close to Open',
        items: [{
          kind: 'consonant',
          value: {
            kind: 'localised',
            lang: 'en',
            where: { kind: 'any' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'any' },
            height: { kind: 'exact', value: 'close' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'any' },
            height: { kind: 'exact', value: 'open' },
          },
        }],
      }],
    }],
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'one-of', options: [1] },
    },
  },
  {
    label: 'Extreme',
    group: 3,
    enable: true,
    notation: {
      tags: { kind: 'contains-one-of', options: ["step", "arpeggio"] },
      difficulty: { kind: 'range', min: 1, max: 3 },
      scale: { kind: 'diatonic' },
    },
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'one-of', options: [1, 2] },
    },
    phonetics: [{
      kind: 'options',
      options: [{
        // handles the gug exercise
        kind: 'syllable',
        label: "Glottal back vowel training",
        items: [{
          kind: 'consonant',
          value: { kind: 'exact', value: 'voiced_velar_plosive' }
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'any' },
            backness: { kind: 'exact', value: 'back' },
            height: { kind: 'any' },
          },
        }, {
          kind: 'consonant',
          value: { kind: 'exact', value: 'voiced_velar_plosive' },
        }, {
          kind: 'consonant',
          value: { kind: 'exact', value: 'voiceless_glottal_plosive' },
        }],
      }, {
        // handles the bow exercise
        kind: 'syllable',
        label: "Plosive Back Vowel",
        items: [{
          kind: 'consonant',
          value: {
            kind: 'localised',
            lang: 'en',
            where: {
              kind: 'match',
              manner: { kind: 'exact', value: 'plosive' },
              voiced: { kind: 'exact', value: true },
              place: { kind: 'any' },
            },
          },
        }, {
          kind: 'vowel',
          value: { kind: 'any' },
        }, {
          kind: 'vowel',
          value: { kind: 'exact', value: 'nearClose_nearBack_rounded' },
        }],
      }, {
        kind: 'syllable',
        label: 'Mwah derivative',
        items: [{
          kind: 'consonant',
          value: {
            kind: 'localised',
            lang: 'en',
            where: { kind: 'any' },
          },
        }, {
          kind: 'consonant',
          value: { kind: 'exact', value: 'coarticulated_voiced_labialVelar_fricate' },
        }, {
          kind: 'vowel',
          where: {
            kind: 'match',
            roundness: { kind: 'exact', value: false },
            backness: { kind: 'exact', value: 'back' },
            height: { kind: 'any' },
          },
        }, {
          kind: 'vowel',
          value: {
            kind: 'match',
            roundness: { kind: 'any' },
            backness: { kind: 'exact', value: 'front' },
            height: { kind: 'any' },
          },
        }, {
          kind: 'consonant',
          value: {
            kind: 'exact',
            value: 'voiceless_glottal_nonsibilantFricative',
          },
        }],
      }],
    }],
  },
  {
    label: "Alternating consonants & Vowels",
    group: 4,
    enable: true,
    notation: {
      tags: { kind: 'contains-one-of', options: ["step", "arpeggio"] },
      difficulty: { kind: 'range', min: 1, max: 3 },
      scale: { kind: 'diatonic' },
    },
    phonetics: [{
      kind: 'consonant',
      value: {
        kind: 'localised',
        lang: 'en',
        where: { kind: 'any' },
      },
    }, {
      kind: 'vowel',
      value: { kind: 'any' },
    }, {
      kind: 'consonant',
      value: {
        kind: 'localised',
        lang: 'en',
        where: { kind: 'any' },
      },
    }, {
      kind: 'vowel',
      value: { kind: 'any' },
    }],
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'one-of', options: [1, 2] },
    },
  },
  {
    label: "Repeative Step/Arp",
    group: 4,
    enable: true,
    notation: {
      tags: { kind: 'contains-one-of', options: ["step", "arpeggio"] },
      difficulty: { kind: 'range', min: 2, max: 4 },
      scale: { kind: 'diatonic' },
    },
    phonetics: [{
      kind: 'consonant',
      value: {
        kind: 'localised',
        lang: 'en',
        where: { kind: 'any' },
      },
    }, {
      kind: 'vowel',
      value: { kind: 'any' },
    }],
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'one-of', options: [2, 3] },
    },
  },
  {
    label: "Alternating",
    group: 4,
    enable: true,
    notation: {
      tags: { kind: 'any' },
      difficulty: { kind: 'exact', value: 5 },
      scale: { kind: 'diatonic' },
    },
    phonetics: [{
      kind: 'consonant',
      value: {
        kind: 'localised',
        lang: 'en',
        where: { kind: 'any' },
      },
    }, {
      kind: 'vowel',
      value: { kind: 'any' },
    }],
    variation: {
      direction: { kind: 'any' },
      iteration: { kind: 'exact', value: 1 },
    },
  },
];
