import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { createPreferences } from './preferences/create';
import { createOutputViewer } from './output/output';
import styles from './application.module.css';
import {
  ApplicationPresenter,
  ApplicationStore,
} from './application_presenter';

export function createApplication({
  data: {
    consonants,
    language,
    meta,
    notation,
    vowels,
  },
}) {
  const {
    Output,
    outputPresenter,
    outputStore,
  } = createOutputViewer({
    meta,
    consonants,
    vowels,
  });

  const {
    Preferences,
    preferencePresenter,
    preferenceStore,
  } = createPreferences({
    data: {
      consonants,
      language,
      meta,
      notation,
      vowels,
    },
  });

  const presenter = new ApplicationPresenter(
    preferencePresenter,
    outputPresenter,
  );

  const store = new ApplicationStore(
    preferenceStore,
    outputStore,
  );

  const onGenerate = () => presenter.onGenerate(store);

  return observer(() => (
    <Application
      onGenerate={onGenerate}
      Output={Output}
      Preferences={Preferences}
    />
  ));
}

export function Application({
  Output,
  Preferences,
  onGenerate,
}) {
  useEffect(() => onGenerate(), []);

  return (
    <div className={styles.application}>
      <div className={styles.content}>
        <h1>Singing Exercise Prompts</h1>
        <Output/>
      </div>
      <div className={styles.footer}>
        <button className={styles.button} onClick={onGenerate}>
          Refresh 🔄
        </button>
      </div>
    </div>
  );
}
