import { makeObservable, observable } from "mobx"

export class RequirementStore {
  id = undefined;
  label = undefined;
  group = undefined;
  enable = undefined;
  notation = undefined;
  phonetic = undefined;
  variation = undefined;

  constructor(
    id,
    label,
    group,
    enable,
    notation,
    phonetic,
    variation,
  ) {
    makeObservable(this, {
      label: observable.ref,
      group: observable.ref,
      enable: observable.ref,
      variation: observable.ref,
    });
    this.id = id;
    this.label = label;
    this.group = group;
    this.enable = enable;
    this.notation = notation;
    this.phonetic = phonetic;
    this.variation = variation;
  }
}

export class RequirementPresenter {
  #notation;
  #phonetic;

  constructor(
    notationRequirementPresenter,
    phoneticRequirementPresenter,
  ) {
    this.#notation = notationRequirementPresenter;
    this.#phonetic = phoneticRequirementPresenter;
  }

  createStore({
    id,
    label,
    group,
    enable,
    notation,
    phonetics,
    variation,
  }) {
    return new RequirementStore(
      id,
      label,
      group,
      enable,
      this.#notation.createStore(notation),
      this.#phonetic.createStore(phonetics),
      variation,
    );
  }

  createExercise(store) {
    const { notation, scale } = this.#notation.createExercise(store.notation);
    const direction = pickDirection(store.variation.direction);
    const iteration = pickIteration(store.variation.iteration);
    const phonetics = this.#phonetic.createPhonetics(store.phonetic);

    const stepsInDirection = direction.reversed
      ? notation.steps.backward
      : notation.steps.forward;

    const steps = stepsInDirection.map(bar => (
      bar.map(step =>
        scale.steps[step % scale.steps.length] +
        (Math.floor(step / scale.steps.length) * 12)
      )
    ));

    const name = `${scale.name}, ${
      direction.description
    } ${
      notation.name
    }, ${iteration.description}`;

    let title = store.label;
    if (phonetics.label) {
      title = `${title}, ${phonetics.label}`;
    }

    let about = `/${phonetics.sound}/ ${name}`;
    if (phonetics.about) {
      about = `${about}. ${phonetics.about}`;
    }

    return {
      title,
      group: store.group,
      enable: store.enable,
      about,
      phonetics,
      steps: {
        name,
        steps,
        help: notation.help,
      },
    };
  }
}

function pickIteration(variation) {
  let count;
  switch (variation.kind) {
    case 'exact':
      count = variation.value;
      break;
    case 'one-of':
      count = pickRandom(variation.options);
      break;
    default:
      throw new Error(variation.kind);
  }

  return {
    iteration: count,
    description: `x${count}`,
  };
}

function pickDirection(variation) {
  let reversed;
  switch (variation.kind) {
    case 'any':
      reversed = Math.random() > 0.5;
      break;
    case 'forward':
      reversed = false;
      break;
    case 'backward':
      reversed = true;
      break;
    default:
      throw new Error(variation.kind);
  }
  return {
    reversed,
    description: reversed ? 'Backward' : 'Forward',
  };
}

function pickRandom(list) {
  return list[Math.floor(Math.random() * list.length)]
}
