import { makeObservable, observable } from "mobx"

class VowelRequirementStore {
  constraint = undefined;

  constructor(constraint) {
    makeObservable(this, {
      constraint: observable.ref,
    });
    this.constraint = constraint;
  }
}

export class VowelRequirementPresenter {
  #vowelConfig = undefined;
  #metaConfig = undefined;

  constructor(vowelConfig, metaConfig) {
    this.#vowelConfig = vowelConfig;
    this.#metaConfig = metaConfig;
  }

  createStore(constraint = { kind: 'any' }) {
    return new VowelRequirementStore(constraint);
  }

  createVowel(store) {
    try {
      const vowel = this.#resolveVowel(store);

      return {
        glyph: vowel.glyph,
        value: { kind: 'vowel', vowel }
      };
    } catch (error) {
      console.error(store.constraint);
      throw new Error(error);
    }
  }

  #resolveVowel(store) {
    const { constraint } = store;

    switch (constraint.kind) {
      case 'exact':
        return this.#vowelConfig[constraint.value];

      case 'match':
        return this.#matchVowel(store, constraint);

      case 'any':
        return pickRandom(Object.values(this.#vowelConfig));

      default:
        throw new Error(constraint);
    }
  }

  #matchVowel(store, constraint) {
    const {
      roundness = { kind: 'any' },
      backness = { kind: 'any' },
      height = { kind: 'any' },
    } = constraint;
    const lsa = Object.values(this.#vowelConfig);

    const lsb = lsa.filter(vowel => {
      switch (roundness.kind) {
        case 'any':
          return true;

        case 'exact': {
          const a = encodeRoundness(vowel.roundness);
          return a === roundness.value;
        }

        default:
          throw new Error('unknown roundness constraint');
      }
    });

    const lsc = lsb.filter(vowel => {
      switch (backness.kind) {
        case 'any':
          return true;

        case 'exact':
          return vowel.backness === backness.value;

        default:
          throw new Error('unknown backness constraint');
      }
    });

    const lsd = lsc.filter(vowel => {
      switch (height.kind) {
        case 'any':
          return true;

        case 'exact':
          return vowel.height === height.value;

        default:
          throw new Error('unknown height constraint');
      }
    });

    return pickRandom(lsd);
  }
}

function encodeRoundness(roundness) {
  if (roundness === 'unrounded') return false;
  if (roundness === 'rounded') return true;
  return null;
}

function pickRandom(list) {
  return list[Math.floor(Math.random() * list.length)]
}
