import { makeObservable, observable } from "mobx"

const diatonicScaleList = [
  'lydian',
  'ionian',
  'mixolydian',
  'dorian',
  'aeolian',
  'phrygian',
  'locrian',
];

const diatonicScaleSet = new Set(diatonicScaleList);

class NotationRequirementStore {
  tags = undefined;
  scale = undefined;
  difficulty = undefined;

  constructor(
    tags = { kind: 'any' },
    scale = { kind: 'any' },
    difficulty = { kind: 'any' },
  ) {
    makeObservable(this, {
      tags: observable.ref,
      scale: observable.ref,
      difficulty: observable.ref,
    });
    this.tags = tags;
    this.scale = scale;
    this.difficulty = difficulty;
  }
}

export class NotationRequirementPresenter {
  #metaConfig;
  #notationConfig;

  constructor(notationConfig, metaConfig) {
    this.#notationConfig = notationConfig;
    this.#metaConfig = metaConfig;
  }

  createStore({ tags, scale, difficulty }) {
    return new NotationRequirementStore(tags, scale, difficulty);
  }

  createExercise(store) {
    const notation = this.#pickExerciseNotation(store);
    const scale = this.#pickScale(store, notation.scale);
    return { notation, scale };
  }

  #pickExerciseNotation(store) {
    const { scale, tags, difficulty: d } = store;

    const xsa = this.#notationConfig.filter(e => {
      switch (d.kind) {
        case 'any':
          return true;

        case 'exact':
          return e.difficulty === d.value;

        case 'range':
          return d.min <= e.difficulty && d.max >= e.difficulty;

        default:
          throw new Error(d.kind);
      }
    });

    let xsb;

    if (tags.kind === 'any') {
      xsb = xsa;
    } else if (tags.kind === 'contains-one-of') {
      xsb = xsa.filter(e => (
        e.tags.some(o => tags.options.includes(o))
      ));
    }

    const xsc = xsb.filter(e => {
      let compat;
      switch (e.scale.kind) {
        case 'any':
          return true;

        case 'exact':
          compat = [e.scale.value];
          break;

        case 'diatonic':
          compat = diatonicScaleList;
          break;

        case 'allow':
          compat = e.scale.options;
          break;

        default:
          throw new Error(e.scale.kind);
      }

      for (const it of compat) {
        switch (scale.kind) {
          case 'diatonic':
            if (diatonicScaleSet.has(it)) return true;
            break;

          default:
            throw new Error(scale.kind);
        }
      }

      return false;
    });

    return pickRandom(xsc);
  }

  #pickScale(store, exercise) {
    const scales = this.#metaConfig.notation.scale;

    const passRequirements = scales.filter(scale => {
      return [store.scale, exercise].every(req => {
        switch (req.kind) {
          case 'any':
            return true;

          case 'exact':
            return req.value === scale.id;

          case 'allow':
            return req.options.includes(scale.id);

          case 'diatonic':
            return diatonicScaleSet.has(scale.id);

          default:
            throw new Error(req.kind);
        }
      });
    });

    return pickRandom(passRequirements);
  }
}

function pickRandom(list) {
  return list[Math.floor(Math.random() * list.length)]
}
