import { makeObservable, observable, action } from "mobx"

export class ApplicationStore {
  preference = undefined;
  output = undefined;

  constructor(
    preferenceStore,
    outputStore,
  ) {
    this.preference = preferenceStore;
    this.output = outputStore;
  }
}

export class ApplicationPresenter {
  #preference = undefined;
  #output = undefined;

  constructor(
    preferencePresenter,
    outputPresenter,
  ) {
    this.#preference = preferencePresenter;
    this.#output = outputPresenter;
  }

  onGenerate(store) {
    try {
      const exercises = this.#preference.createExercises(
        store.preference,
      );

      this.#output.setExercises(store.output, exercises);
    } catch (e) {
      console.error(e);
    }
  }
}
