import { useId, useMemo } from 'react';
import styles from './exercise.module.css';

export function createExercise({
  meta,
  IpaLegend,
  Notation,
}) {
  const Step = props => <ExerciseSteps meta={meta} {...props}/>;
  return props => (
    <Exercise
      IpaLegend={IpaLegend}
      Notation={Notation}
      ExerciseSteps={Step}
      {...props}
    />
  );
}

export function Exercise({
  ExerciseSteps,
  IpaLegend,
  Notation,
  exercise: {
    about,
    phonetics: { sound, legend },
    steps,
    title,
  },
}) {
  const titleId = useId();
  const listenUrl = `http://ipa-reader.xyz/?text=${sound}`;

  return (
    <div className={styles.exercise} aria-labelledby={titleId}>
      <div className={styles.info}>
        <h3 id={titleId}>{title}</h3>
        <p>{about}</p>
      </div>
      <Notation steps={steps.steps}/>
      <ExerciseSteps steps={steps}/>
      <IpaLegend sound={sound} legend={legend}/>
      <p className={styles.ipaReader}>
        Listen on <a href={listenUrl} target="_blank">IPA Reader</a>.
      </p>
    </div>
  );
}

function ExerciseSteps({
  meta,
  steps: { steps, name, help },
}) {
  const titleId = useId();

  const example = useMemo(() => {
    return steps.map((s, i) => (
      <li key={i}>{s.map(i => meta.notation.notes[i]).join(' ')}</li>
    ));
  }, [steps]);

  return (
    <div aria-labelledby={titleId}>
      <p><strong id={titleId}>Exercise Instructions</strong></p>
      <ul>
        <li><p>{name}</p></li>
        <li><p>{help}</p></li>
        <li><p>Notation:</p><ul>{example}</ul></li>
      </ul>
    </div>
  );
}
