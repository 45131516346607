import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { createAudioService } from './audio/audio_service';
import { createExercise } from './exercise/exercise';
import { createIpaLegend } from './ipa-legend/ipa-legend';
import { createNotation } from './notation/create';
import styles from './output.module.css';
import { OutputPresenter, OutputStore } from './output_presenter';

export function createOutputViewer({
  meta,
  vowels,
  consonants,
}) {
  const context = new AudioContext();

  const Exercise = createExercise({
    meta,
    IpaLegend: createIpaLegend({
      audio: createAudioService({
        context,
        consonants,
        vowels,
      }),
    }),
    Notation: createNotation({
      context,
    }),
  });

  const presenter = new OutputPresenter();
  const store = new OutputStore();

  return {
    outputStore: store,
    outputPresenter: presenter,
    Output: observer(props => (
      <OutputViewer
        exercises={store.exercises}
        Exercise={Exercise}
        {...props}
      />
    ))
  };
}

export function OutputViewer({
  onGenerate,
  exercises,
  Exercise,
}) {
  const body = useMemo(() => {
    if (exercises.length === 0) {
      return <p>Click Generate Exercise</p>;
    }

    return exercises.map(e => <Exercise key={e.title} exercise={e}/>);
  }, [exercises]);

  return (
    <div className={styles.output}>
      <h2 className={styles.outputTitle}>Exercises</h2>
      <p className={styles.outputHint}>
        For each exercise, you should start at either of the
        start or end your range and repeat the exercise as
        you chromatically move towards the other end of your
        range. Although if the exercise is a "Forward"
        exercise it might make start at the lower end of your
        range and move towards the higher, and vice versa for
        for a "Backward" exercise (go from high to low).{" "}
        <b>
          Ultimately these are Prompts and your welcome to take
          whatever approach you like.
        </b>
      </p>
      <div className={styles.exercises}>
        {body}
      </div>
    </div>
  );
}
